<template>
	<div class="classify">
		<router-link to="/search" tag="div" class="details-search">
			<input type="text" placeholder="请输入商品名称" disabled class="search" />
		</router-link>

		<ul class="search-tab">
			<li @click="clickTab(0)">
				<p :class="{ color: 0 == tabIndex }">综合</p>
			</li>
			<li @click="clickTab(1)">
				<p :class="{ color: 1 == tabIndex }">最新</p>
			</li>
			<li @click="clickTab(2)">
				<p :class="{ color: 2 == tabIndex }">热销</p>
			</li>
			<li @click="clickTab(3)">
				<p :class="{ color: 3 == tabIndex }">价格最高</p>
			</li>
			<li @click="clickTab(4)">
				<p :class="{ color: 4 == tabIndex }">价格最低</p>
			</li>
		</ul>

		<div class="list-wrap-3">
			<div class="list-wrap-left-1">
				<ul class="list-wrap-left">
					<li :class="{ selected: cur == index }" @click="selectedCate(index, item)"
						v-for="(item, index) in allclassify" :key="index">{{ item.cate_name }}</li>
				</ul>
			</div>
			<div class="list-wrap-right">
				<goods-list listType="classify" :recommends="cateGoods" :finished="finished" :loading="loading"
					:isShow="isShow" @getMore="getMore" ref="goodsList"></goods-list>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		classIfy,
		classIfyGoods
	} from "../../http/api";
	import GoodsList from "../../components/goods/GoodsList";
	export default {
		components: {
			GoodsList
		},
		data() {
			return {
				site_code: '',
				id: 0, //  分类ID
				cur: 0, //  当前选中项
				tabIndex: 0, //  当前排序选中项
				allclassify: [{
					cate_id: 0,
					cate_name: "全部"
				}],

				isShow: false,
				isLoad: true,
				pages: 1,
				loading: false,
				finished: false,
				cateGoods: []
			};
		},
		mounted() {
			let curUrl = this.$route.path;
			if (this.$route.query.id) {
				this.site_code = this.$LStorage.getItem("curSiteInfo").site_code;
				this.id = this.$route.query.id;
			}
			// 获取分类
			this.AllClassIfy();
			this.classGoods();
		},
		beforeRouteEnter(to, from, next) {
			if (from.name == "Details") {
				to.meta.keepAlive = true;
			} else {
				to.meta.keepAlive = false;
			}
			next();
		},
		methods: {
			//  设置选中
			setCur() {
				this.allclassify.forEach((value, index, array) => {
					if (value.cate_id == this.id) {
						this.cur = index;
					}
				});
			},

			//  点击分类获取商品分类
			selectedCate(inx, item) {
				console.log(item);
				if (this.id != item.cate_id) {
					this.cur = inx;
					this.id = item.cate_id;
					this.cateGoods = [];
					this.pages = 1;
					this.isLoad = true;
					this.classGoods();
				}
			},

			//  点击综合重新获取数据
			clickTab(inx) {
				if (inx !== this.tabIndex) {
					this.tabIndex = inx;
					this.cateGoods = [];
					this.pages = 1;
					this.isLoad = true;
					this.classGoods();
				}
			},

			//  获取所有分类
			async AllClassIfy() {
				const res = await classIfy({
					data: {
						site_code: this.site_code
					}
				});
				this.allclassify = this.allclassify.concat(res.data);
				this.setCur();
			},

			//  获取分类商品
			async classGoods() {
				if (this.isLoad) {
					const res = await classIfyGoods({
						data: {
							pages: this.pages,
							site_code: this.site_code,
							cate_id: this.id,
							is_sort: this.tabIndex
						}
					});
					this.isShow = true;
					this.loading = false;
					this.$refs.goodsList.changeLoading(false);
					if (res.numPage == res.data.length) {
						this.pages++;
						this.finished = false;
					} else {
						this.isLoad = false;
						this.finished = true;
					}
					this.cateGoods = this.cateGoods.concat(res.data);
				}
			},

			//  点击查看更多
			getMore() {
				this.classGoods();
			}
		}
	};
</script>

<style lang="scss" scoped>
	.classify {
		width: 100%;

		.details-top {
			width: 100%;
			display: flex;
			justify-content: space-between;
			padding: 0.3rem;
			box-sizing: border-box;
			font-size: 0.38rem;
			background: #f4f4f4;
		}

		.details-search {
			width: calc(100% - 0.48rem);
			margin: 0 auto;
			padding-top: 0.16rem;

			.search {
				width: 100%;
				height: 0.65rem;
				font-size: 0.28rem;
				background: #f2f2f2;
				border-radius: 0.33rem;
				margin-bottom: 0.51rem;
				display: block;
				padding-left: 0.73rem;
				box-sizing: border-box;
				background-image: url("../../assets/serch.png");
				background-repeat: no-repeat;
				background-size: 0.28rem 0.28rem;
				background-position: 0.3rem center;
			}
		}

		.search-tab {
			width: calc(100% - 0.48rem);
			margin: 0 auto;
			display: flex;
			align-items: center;
			justify-content: space-around;
			height: 0.4rem;

			li {
				width: 2.5rem;
				font-size: 0.24rem;
				display: flex;
				align-items: center;
				flex-wrap: nowrap;
				justify-content: center;

				svg {
					width: 0.24rem;
					height: 0.24rem;
				}

				path.color {
					fill: #eb5d29;
				}
			}

			.color {
				color: #eb5d29;
			}

			li:nth-child(4) {
				position: relative;

				#sanjiao {
					position: absolute;
					top: -0.06rem;
					right: 0.34rem;
					transform: rotateZ(180deg);
				}

				#sanjiao1 {
					position: absolute;
					top: -0rem;
					right: 0.34rem;
				}

				.icon {
					margin-top: 0.08rem;
				}
			}
		}

		.list-wrap-3 {
			width: 100%;
			background: #f6f6f6;
			margin-top: 0.35rem;
			display: flex;

			.list-wrap-left-1 {
				width: 1.6rem;
				height: calc(100vh - 2.07rem);
				overflow-x: hidden;
				overflow-y: scroll;
				background: #ffff;

				.list-wrap-left {
					width: 100%;
					height: max-content;
					background: #ffff;

					li {
						border-left: 0.05rem solid rgba($color: #fff, $alpha: 0);
						width: 100%;
						font-size: 0.24rem;
						color: #c0c099;
						text-align: center;
						height: 0.88rem;
						line-height: 0.88rem;
						color: #737371;
					}

					.selected {
						border-color: #e40011;
						color: #e40011;
						background: #f6f6f6;
					}
				}
			}

			.list-wrap-right {
				width: 100%;
				height: calc(100vh - 2.07rem);
				overflow-y: scroll;
			}
		}
	}

	.content .content-list {
		width: auto;
	}

	.classify .list-wrap-3 .list-wrap-right {
		width: 78vw !important;
		padding: 0 2vw;
	}

	.content .content-list {
		width: 74vw !important;
	}

	.list-button {
		justify-content: center;
	}
</style>
